import cn from "classnames";
import Loader from "../../Loader";
import Svg from "../../Svg";
import useData from "../../../hook/useData";
import { Snackbar } from "@mui/material";
import "./ChatsDetails.scss";
import { useDispatch, useSelector } from "react-redux";
import ChatTabs from "../ChatTabs/ChatTabs";
import ChatConversationContext from "../ChatConversationContext/ChatConversationContext";
import SnackbarMessage from "../../SnackbarMessage/SnackbarMessage";
import { useEffect, useState } from "react";
import {
  isChatFormOpen,
  isAppointmentFormOpen,
} from "../../../store/actions/conversationDealerChatActions";

const ChatsDetails = ({ cid, onToggleDetailsCollapsed }) => {
  const dispatch = useDispatch();

  const selectedProductId = useSelector(
    (state) => state?.user?.auth?.selectedProduct?.Category
  );
  const isOpen = useSelector((state) => state.conversationDealerChat.isOpen);
  const appointmentFormOpen = useSelector(
    (state) => state.conversationDealerChat.isAppointmentFormOpen
  );
  const [showSnackbar, setShowSnackbar] = useState(false);

  const { data, status, error } = useData(
    "getGeneralLeadInfo",
    {
      cid,
    },
    [cid]
  );

  useEffect(() => {
    return () => {
      dispatch(isChatFormOpen(false));
      dispatch(isAppointmentFormOpen(false));
    };
  }, []);

  const render = () => {
    if (status === "error") {
      return error || "Error";
    }

    if (status === "init" || status === "pending") {
      return <Loader />;
    }

    const highlightedEvents = data.HighlightedEvents?.map((event, idx) => {
      const icon = event.Event === "SalespersonReplied" ? "man" : "bell";
      return (
        <div
          key={idx}
          className={cn("ChatsDetails-notification", {
            "is-important": event.HighlightType === 1,
          })}
        >
          <div className="ChatsDetails-notificationIcon">
            <Svg w="22" h="26" i={icon} />
          </div>
          <div className="ChatsDetails-notificationText">{event.EventName}</div>
        </div>
      );
    });
    if (isOpen || appointmentFormOpen) {
      return (
        <ChatTabs
          data={data}
          onToggleDetailsCollapsed={onToggleDetailsCollapsed}
          selectedProductId={selectedProductId}
          highlightedEvents={highlightedEvents}
          setShowSnackbar={setShowSnackbar}
        />
      );
    }
    return (
      <div style={{ padding: "20px" }}>
        <ChatConversationContext
          data={data}
          vehicleData={data?.vehicleInfo}
          onToggleDetailsCollapsed={onToggleDetailsCollapsed}
          selectedProductId={selectedProductId}
          highlightedEvents={highlightedEvents}
        />
      </div>
    );
  };

  return (
    <>
      <Snackbar
        open={showSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
      >
        <SnackbarMessage
          elevation={2}
          variant="filled"
          onClick={() => {
            setShowSnackbar(false);
          }}
          severity={"success"}
        >
          The Form Has Been Successfully Submitted!
        </SnackbarMessage>
      </Snackbar>
      <div className="ChatssDetails">{render()}</div>
    </>
  );
};

export default ChatsDetails;
