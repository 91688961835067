import React, { useContext, useEffect, useState } from "react";

import cn from "classnames";

import TableContext from "../TableContext";
import TableCell from "../TableCell";

import "./TableRow.scss";
import useData from "../../../hook/useData";
import { checkAccountInsightProvider } from "../../../utils/checkAccountInsightProvider";
import Loader from '../../Loader'

const TableRow = ({ className, row, events = {}, setFreezeTable }) => {
    const [{ columns }] = useContext(TableContext);
    const [subSourcesData, setSubSourcesData] = useState(null)
    const rowEventsData = events.rows || [];
    const cellEventsData = events.cells || [];
    const [dataToUpdate, setDataUpdate] = useState(null)

    if (!row) return null;
    let endpoint = null
    if (row.expanded) {
        endpoint = "getAccountSubSourceSettings"
    }

    const { data, status, error } = useData(endpoint, { "source_id": row.SourceId }, [row], true);

    const {
        data: updateSubsourceData,
        status: updateSubsourceResponseStatus,
        execute: updateSubsourceExecute,
    } = useData("updateAccountSubsourceSettings", dataToUpdate, [dataToUpdate], false);


    useEffect(async () => {
        if (dataToUpdate) {
            setFreezeTable(true)
            await updateSubsourceExecute()
            setFreezeTable(false)
        }
    }, [dataToUpdate])

    const updateSubSourceStatus = (row) => {
        row.Status = !row.Status
        setDataUpdate({
            "service_type": "LEAD_ENGAGE",
            "Status": row.Status,
            "SourceName": row.MainSourceName,
            "SubSourceName": row.SourceName,
            "SourceId": row.MainSourceId,
            "SubSourceId": row.SourceId
        })
        // CALL API TO UPDATE STATUS OFF THE SUB-SOURCE

        // UPDATE STATE OF THE SUB-SOURCES DATA
        // const subSourceIdx = subSourcesData.findIndex((subSource) => {
        //     return subSource.SourceId === row.SourceId
        // });
        // if (subSourceIdx === -1) {
        //     console.log('THERE WAS SOMETHING STRANGE GOING ON HERE...')
        //     return
        // }
        // setSubSourcesData(prevState => {
        //     return [...prevState.slice(0, subSourceIdx), row,
        //         ...prevState.slice(subSourceIdx + 1)]
        // })
    }

    const doEverythingWithSubSources = () => {
        if (endpoint === "getAccountSubSourceSettings") {
            const fetchedSubSources = data.Items;
            for (const subsource of fetchedSubSources) {
                subsource['MainSourceName'] = row['SourceName']
                subsource['MainSourceId'] = row['SourceId']
            }
            setSubSourcesData(fetchedSubSources)
        }
    }

    useEffect(() => {
        if (data && data.Items) {
            doEverythingWithSubSources()
        }
    }, [data])


    const prepareCellContent = ({ formatter, id: columnId }) => {
        let res;

        if (formatter) {
            if (cellEventsData && cellEventsData.length) {
                cellEventsData.forEach((event) => {
                    if (event.id === columnId) res = formatter(row, event.events);
                });
            } else {
                res = formatter(row);
            }
        } else {
            res = row[columnId];
        }

        return res;
    };

    const rowEvents = rowEventsData.reduce((res, rowEvent) => {
        const eventArg = row[rowEvent.id] || null;

        return {
            ...res,
            [rowEvent.eventName]: () => rowEvent.eventHandler(eventArg),
        };
    }, {});

    const generateSubSourceRows = (row) => {
        // row is the main source!!!
        if (row?.expanded && data?.Items && subSourcesData !== null) {
            console.log('What is the subSourcesData: ', subSourcesData)
            return data.Items.map((expandedRow, idx) => {
                expandedRow['MainSourceName'] = row['SourceName']
                expandedRow['MainSourceId'] = row['SourceId']
                expandedRow['MainSourceStatus'] = row.Status
                events.cells[0]["events"] = { "handleSubSourceUpdate": updateSubSourceStatus, ...events.cells[0]["events"] }
                return (
                    <TableRow
                        className={cn("TableRow--sub-source")}
                        key={expandedRow[columns[0].id]}
                        row={expandedRow}
                        events={events}
                    />
                );
            });
        } else if (row?.expanded && endpoint !== null && data === null) {
            return <Loader />;
        } else {
            return null;
        }
    }

    return (
        <>
            <tr
                className={cn(
                    "TableRow",
                    { "TableRow--interactive": rowEventsData.length },
                    className
                )}
                {...rowEvents}
            >
                {columns.map((column, idx) => {
                    if (column.hidden) return false;
                    if (column.hiddenColumn) return false;
                    if ("insight_provider" in column && !checkAccountInsightProvider(column.insight_provider)) {
                        return false;
                    }

                    const { className = false, id } = column;
                    const extraProps = {};

                    if (column.group) extraProps.colSpan = column.group.length;

                    const cellContent = prepareCellContent(column);

                    return (
                        <TableCell
                            key={row.source_name + id}
                            className={cn(className, {
                                "TableCell--first": idx === 0,
                                "TableCell--last": idx === columns.length - 1,
                            })}
                            {...extraProps}
                        >
                            {cellContent}
                        </TableCell>
                    );
                })}
            </tr>
            {generateSubSourceRows(row)}
        </>
    );
};

export default TableRow;
