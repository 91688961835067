import { useSelector } from "react-redux";

export const checkAccountInsightProvider = (
  insight_provider,
  accountId = null
) => {
  const childAccounts =
    useSelector((state) => state.user.auth.data.user_info?.ChildAccounts) || [];

  const dealerId = useSelector(
      (state) => state.user.auth?.data?.user_info?.DealerId
    )
  if (!accountId)
    accountId = dealerId;

  const acc = childAccounts.find((a) => a.id === accountId);

  return acc.insight_providers?.some(
    (i) => i.provider_name === insight_provider
  );
};
