const columnsMap = {
  'NameSurname': 'Name, Surname',
  'FordDirectScore': 'EPMScore'
}

const transformConversationsFieldToDB = (field) => {
  return columnsMap[field] || field;
}

export default transformConversationsFieldToDB;
