import { formatValue } from "../../../utils/formatValue";
import cn from "classnames";

import "./LatestVehicleInterest.scss";
const LatestVehicleInterest = ({ vehicleData }) => {
  const generateTitle = () => {
    const { Year, Model, Make } = vehicleData || {};
    const titleParts = [Year ? Year : "", Make ? Make : "", Model ? Model : ""];
    return titleParts.filter(Boolean).join(" ").trim();
  };

  const getPrice = () => {
    return vehicleData?.Price === "0" || !vehicleData?.Price
      ? "-"
      : vehicleData.Price;
  };

  return (
    <div className="LatestVehicleInterestArea">
      <div className="ConversationsDetails-objectName">{generateTitle()}</div>
      <div className="ConversationsDetails-objectInfo">
        <div className="ConversationsDetails-objectValue">${getPrice()}</div>
        <div
          className={cn("ConversationsDetails-objectStatus", {
            "ConversationsDetails-objectStatus--available":
              vehicleData.Available,
            "ConversationsDetails-objectStatus--unavailable":
              !vehicleData.Available,
          })}
        >
          {vehicleData.Available ? "Available" : "Unavailable"}
        </div>
      </div>
      {vehicleData?.ImageUrl && (
        <div className="ConversationsDetails-objectPhoto">
          <img src={vehicleData.ImageUrl} alt="" />
        </div>
      )}
      <div className="EachInfoArea">
        <div className="InfoTitle">VIN:</div>
        <div className="InfoValue">{vehicleData.Vin}</div>
      </div>
      <div className="EachInfoArea">
        <div className="InfoTitle">STOCK:</div>
        <div className="InfoValue">{vehicleData.Stock}</div>
      </div>
    </div>
  );
};

export default LatestVehicleInterest;
